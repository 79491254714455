class PixelUtils {
  // This function flats objects with nested keys separated by '.'
  // It is a recursive function, so use it wisely
  // Source: https://stackoverflow.com/a/59787588
  flattenObject(ob, prefix = false, result = null) {
    result = result || {};

    if (prefix && typeof ob === 'object' && ob !== null && Object.keys(ob).length === 0) {
      result[prefix] = Array.isArray(ob) ? [] : {};
      return result;
    }

    prefix = prefix ? prefix + '.' : '';

    for (const i in ob) {
      if (Object.prototype.hasOwnProperty.call(ob, i)) {
        if (typeof ob[i] === 'object' && (Array.isArray(ob[i]) || Object.prototype.toString.call(ob[i]) === '[object Object]') && ob[i] !== null) {
          this.flattenObject(ob[i], prefix + i, result);
        } else {
          result[prefix + i] = ob[i];
        }
      }
    }
    return result;
  }

  parseString(input) {
    return typeof input === 'string' ? input : JSON.stringify(input)
  }

  objectToArray(obj) {
    return Array.from(Object.entries(this.flattenObject(obj)), (item) => ({ 'code': item[0], 'value': this.parseString(item[1]) || '' }) )
  }
}

export default new PixelUtils();