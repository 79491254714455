import { useTranslation } from "next-i18next";
import React, { useRef } from "react";
import IconsImage from 'components/Images/Icons';
import { loaderStaticImage } from "utils/image";
import dynamic from "next/dynamic";
import { FaqProps } from '../Faq/types';
import { sendAnalyticsEvent } from "utils/datalayer";
import { getEnvironment } from "utils/data";
import { useRouter } from "next/router";

const FaqModal = dynamic(() => import('../FaqModal'), { ssr: false });

const QuestionCircleIcon = loaderStaticImage({
  src: 'static/images/icons/question-circle.svg'
});

const FaqMobile = (props: FaqProps) => {
  const { query } = useRouter();
  const { t } = useTranslation()
  const faqModal = useRef(null)
  const environment = getEnvironment(query?.sck);

  const openModal = () => {
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: `${environment}:Product`,
      event_action: 'Click:FaqMobile',
    });

    faqModal.current?.openModal()
  }

  return (
    <>
      <button type="button" onClick={openModal} className="_d-flex _bg-white _w-full _aling-items-center _justify-content-center _text-gray-900 _text-2 _font-weight-bold _text-decoration-underline _px-2 _py-3 _border _rounded-lg">
        <span className="_mr-1"><>{t('faq.title')}</></span>
        <IconsImage src={QuestionCircleIcon} width={20} height={20} className="" />
      </button>
      <FaqModal modalRef={faqModal} {...props} />
    </>
  )
}

export default React.memo(FaqMobile)
