import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { loaderStaticImage } from 'utils/image';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import SectionTitle from 'components/@redesign/SectionTitle';
import AuthorInfo from 'components/@redesign/Author/AuthorInfo';
import ParagraphSection from 'components/@redesign/ParagraphSection';

const Author = ({isMobile}) => {
  const { t } = useTranslation();
  const context = useContext(ProfileContext);
  const hotmarter = context?.profile?.product?.hotmarter;
  const { profile: { productOwner = {} } = {} } = context;
  const ownerHotmarter =
    productOwner && productOwner.name ? productOwner : hotmarter;

  const socialMediaIcons = {
    facebook: {
      icon: loaderStaticImage({ src: 'static/images/icons/facebook-link.svg' }),
      label: `${t('share.facebook')}`
    },
    twitter: {
      icon: loaderStaticImage({ src: 'static/images/icons/twitter-link.svg' }),
      label: `${t('share.twitter')}`
    },
    'youtube-play': {
      icon: loaderStaticImage({ src: 'static/images/icons/youtube-link.svg' }),
      label: `${t('share.youtube')}`
    },
    instagram: {
      icon: loaderStaticImage({ src: 'static/images/icons/instagram-link.svg' }),
      label: `${t('share.instagram')}`
    },
    sparkle: {
      icon: loaderStaticImage({ src: 'static/images/icons/sparkle.svg' }),
      label: `${t('share.sparkle')}`
    }
  };


  return (
    <>
      <SectionTitle text={t('general.hotmarter_info')}/>
      {ownerHotmarter &&
        <AuthorInfo
          ownerHotmarter={ownerHotmarter}
          showSocialMedia={true}
          socialMediaIcons={socialMediaIcons}
          isMobile={isMobile}
          className="_mb-4"/> }
      <ParagraphSection text={ownerHotmarter?.biography} />
    </>
  );
}

export default Author;
