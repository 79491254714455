export const getLanguageSymbol = (language) => {
  language = language ? language.toString().toLowerCase() : '';

  if (language) {
    if (language.match(/^pt/)) {
      return 'pt-br';
    } else if (language.match(/^en/)) {
      return 'en';
    } else if (language.match(/^es/)) {
      return 'es';
    } else if (language.match(/^fr/)) {
      return 'fr';
    } else if (language.match(/^ar/)) {
      return 'ar';
    } else {
      return language;
    }
  }
};
