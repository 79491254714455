type SectionTitleProps = {
  text: string;
  className?: string;
};

const SectionTitle = (props: SectionTitleProps) => {
  const className = props.className || '';

  return (
    <div className={`_mb-5 _text-4 _text-md-5 _font-weight-bold ${className}`}>
      { props.text }
    </div>
  );
};

export default SectionTitle;
