export enum STRUCTURED_DATA_TYPE {
  PRODUCT = 'PRODUCT',
  REVIEW = 'REVIEW',
  COURSE = 'COURSE'
}

const isEvenNumber = (n: string | number) => {
  return Number(n) % 2 == 0;
};

export const getStructuredDataType = (productId: number) => {
  const lastNumberPosition = productId.toString()?.slice(-1);

  if (lastNumberPosition && isEvenNumber(lastNumberPosition)) {
    return STRUCTURED_DATA_TYPE.PRODUCT;
  }

  return STRUCTURED_DATA_TYPE.REVIEW;
};

export const structuredDataActive = (
  productId: number,
  type: string,
  productPriceInfo: DataCheckoutProps,
  reviews?: CustomerReviewProps
) => {
  const firstNumberPosition = String(productId)[0];

  if (
    type === STRUCTURED_DATA_TYPE.PRODUCT &&
    firstNumberPosition &&
    productPriceInfo?.data?.offer?.paymentInfoResponse?.offerPrice?.value > 0
  ) {
    return isEvenNumber(firstNumberPosition);
  }

  if (
    type === STRUCTURED_DATA_TYPE.REVIEW &&
    firstNumberPosition &&
    reviews?.totalReviews &&
    reviews?.rating
  ) {
    return !isEvenNumber(firstNumberPosition);
  }

  return false;
};
