import React from 'react';
import { loaderStaticImage } from 'utils/image';
import { sendAnalyticsEvent } from 'utils/datalayer';
import { useTranslation } from 'next-i18next';
import IconsImage from '../../Images/Icons';

const AccessCourse = ({
  isMobile,
  salesEnabled,
  clubMembershipLink,
  environment,
  productId,
  isBlackNovember = false
}) => {
  const { t } = useTranslation();

  const onAccessClick = () => {
    const cartStatus = salesEnabled ? 'OpenCart' : 'ClosedCart';
    const device = isMobile ? ':Mobile' : '';
    // GA Event
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: `${environment}:Product`,
      event_action: `Click:AccessCourse:${cartStatus}${device}`,
      event_label: `Product:${productId}`
    });
  };

  let ArrowRightSolid = loaderStaticImage({
    src: 'static/images/icons/back_button.png'
  });

  if (isBlackNovember) {
    ArrowRightSolid = loaderStaticImage({
      src: 'static/images/black-november/back_button_bn.png'
    });
  }

  return (
    <a
      href={clubMembershipLink}
      target="_new"
      className="sidebar__access _text-md-1 _text-decoration-underline _text-decoration-underline _border-top"
      onClick={onAccessClick}
    >
      <>
        <div className="_mr-1">
          <>{t('product.access_bought_product')}</>
        </div>
        <IconsImage
          className="sidebar__access-redesign"
          src={ArrowRightSolid}
          width={12}
          height={12}
        />
      </>
    </a>
  );
};

export default React.memo(AccessCourse);
