import { campaignSideBar } from '../../../../constants';
import classnames from 'classnames';

const renderPrice = (
  title: TitleCheckout,
  discountValue: any,
  context: TypeContext,
  campaignData?: any
) => {
  const amount = title?.priceTitle;
  const description = title?.priceRecurrence;
  const currency = '';
  const vat = title?.vat;
  const pricePreviousValue = title?.pricePreviousValue;
  const schedule = context?.checkout?.onlineService;
  const ctaIcon = campaignData?.[campaignSideBar.LOGO]?.urlFinal || '';

  return (
    <div className={classnames('price', { 'price-schedule': schedule })}>
      {pricePreviousValue &&
        discountValue !== 0 &&
        pricePreviousValue !== amount && (
          <div className="price__previous">
            <span className="price__previous__label">{pricePreviousValue}</span>
            {discountValue && !ctaIcon && (
              <span className="price__previous__discount">
                <span className="price__previous__discount__label">
                  -{discountValue}%
                </span>
              </span>
            )}
            {ctaIcon && <img className="price__cta-icon" src={ctaIcon} />}
          </div>
        )}
      <div className={classnames('price--value')}>
        {currency && <span className="price--value--currency">{currency}</span>}
        {amount && (
          <span className="_text-md-5 _text-sm-2 _text-gray-900">{amount}</span>
        )}
      </div>
      {description && <div className="price--description">{description}</div>}
      {vat && <div className="price--vat">{vat}</div>}
    </div>
  );
};

export default renderPrice;
