import React from 'react';
import classNames from 'classnames';

export const renderSalesArguments = (
  showSidebarScrolled: boolean,
  showSalesArguments: boolean,
  salesArguments: Record<string, any>,
  isBlackNovember: boolean,
  t: (arg: string) => void
) => {
  const totalSales = salesArguments.length;

  if (salesArguments && salesArguments.length > 0) {
    return (
      <div
        className={classNames(
          `_mb-md-5 sales-argument--${totalSales}`,
          {
            'sales-argument--hidden': !showSalesArguments
          }
        )}
      >
        <div
          className={classNames('', {
            'sales-argument__list--sticky': showSidebarScrolled
          })}
        >
          {salesArguments.map((salesArgument, index) => {
            const IconComponent = salesArgument?.image?.src || null

            return (
              <div className="_d-flex _align-items-center _mb-4">
                <div className="_d-flex _justify-content-center sales-argument__icon">
                  {IconComponent ? (
                    <IconComponent
                      width={salesArgument?.image?.width}
                      height={salesArgument?.image?.height || 16}
                      className={classNames({
                        'sales-argument__icon--bn': isBlackNovember
                      })}
                    />
                  ) : null}
                </div>
                <div
                  key={`argument-${index}`}
                  className="_text-md-1 _font-weight _ml-3"
                  aria-label={salesArgument?.label}
                >
                  {salesArgument?.label}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
  return null;
};

export default renderSalesArguments;
