import classnames from 'classnames';
import ContentLoader from 'components/Loader/content';

const renderInfoPrice = (
  title: TitleCheckout,
  context: TypeContext,
  isInstallmentPriceOnTop?: any,
  isEventOnline?: boolean
) => {
  if (!title) {
    return null;
  }

  const { infoPrice } = title;

  if (context?.checkout?.loading) {
    return <ContentLoader width="70%" height={30} />
  }

  if (infoPrice) {
    return (
      <div
        className={classnames('copy-text _text-gray-800 _text-2 _font-weight', {
          'price-copy-schedule': context?.checkout?.onlineService,
          'copy-text--installment-price': isInstallmentPriceOnTop,
          'price-copy-event': isEventOnline
        })}
      >
        {infoPrice}
      </div>
    );
  }
};

export default renderInfoPrice;
