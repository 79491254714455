import { TOTAL_STUDENTS } from '../constants';
import { formatDateLocale } from 'utils/date';
import IconCheck from 'static/images/icons/check_black.svg'
import CertificationIcon from 'static/images/icons/certification-redesign.svg'
import DeviceIcon from 'static/images/icons/device-redesign.svg'
import StudentsIcon from 'static/images/icons/students-redesign.svg'
import ReviewIcon from 'static/images/icons/review-redesign.svg'
import HoursIcon from 'static/images/icons/hours-redesign.svg'
import CalendarIcon from 'static/images/icons/warranty-redesign.svg'
import DownloadIcon from 'static/images/icons/watch_offline.svg'

const calculateClassesDuration = (profile, dataSheet) => {
  let totalNumberClasses = 0;
  let totalDurationClasses = 0;
  const HOUR_IN_SECONDS = 3600;

  if (profile?.showModuleContentSummarized) {
    let videosDuration = 0;
    let audiosDuration = 0;

    totalNumberClasses = profile?.clubContentSummarized?.numberOfPages || 0;

    if (profile?.clubContentSummarized?.durationOfVideos > 0) {
      videosDuration = Math.ceil(
        profile.clubContentSummarized.durationOfVideos / HOUR_IN_SECONDS
      );
    }

    if (profile?.clubContentSummarized?.durationOfAudios > 0) {
      audiosDuration = Math.ceil(
        profile.clubContentSummarized.durationOfAudios / HOUR_IN_SECONDS
      );
    }

    totalDurationClasses = videosDuration + audiosDuration;

    return {
      totalNumberClasses,
      totalDurationClasses
    };
  }

  return {
    totalNumberClasses: Number(dataSheet?.totalClasses) || 0,
    totalDurationClasses: Number(dataSheet?.duration) || 0
  };
};

export const mountSalesArguments = (
  t: any,
  profile: Profile,
  dataSheet: DataSheet,
  showSidebarScrolled: boolean,
  showConsuptionPossibilities?: boolean
) => {
  const salesArguments = [];
  const defaultIconSize = 16;
  const plusIconSize = 18;
  const { totalNumberClasses, totalDurationClasses } = calculateClassesDuration(
    profile,
    dataSheet
  );

  if (dataSheet?.warranty) {
    salesArguments.push({
      label: t('sales_arguments.warranty', {
        count: dataSheet.warranty || 7
      }),
      image: {
        src: IconCheck,
        width: defaultIconSize
      }
    });
  }

  if (dataSheet?.certification) {
    salesArguments.push({
      label: t('sales_arguments.certification'),
      image: {
        src: CertificationIcon,
        width: plusIconSize,
        height: plusIconSize
      }
    });
  }

  if (dataSheet?.beginDateTime || dataSheet?.beginDate) {
    let beginDateTimeFormatted;

    if (dataSheet?.beginDateTime) {
      beginDateTimeFormatted = formatDateLocale(new Date(dataSheet?.beginDateTime), 'P');
    } else if (dataSheet?.endDate) {
      beginDateTimeFormatted = dataSheet?.beginDate;
    }

    const formattedLabel = `${t(
      'data_sheet.begin_date'
    )}: ${beginDateTimeFormatted}`;

    salesArguments.push({
      label: formattedLabel,
      image: {
        src: CalendarIcon,
        width: defaultIconSize
      }
    });
  }

  if (dataSheet?.endDateTime || dataSheet?.endDate) {
    let endDateTimeFormatted;

    if (dataSheet?.endDateTime) {
      endDateTimeFormatted = formatDateLocale(new Date(dataSheet?.endDateTime), 'P');
    } else if (dataSheet?.endDate) {
      endDateTimeFormatted = dataSheet?.endDate;
    }

    const formattedLabel = `${t(
      'data_sheet.end_date'
    )}: ${endDateTimeFormatted}`;

    salesArguments.push({
      label: formattedLabel,
      image: {
        src: CalendarIcon,
        width: defaultIconSize
      }
    });
  }

  if (profile?.clubMembershipLink) {
    if (showSidebarScrolled) {
      salesArguments.push({
        label: showConsuptionPossibilities
          ? t('sales_arguments.content_anywhere')
          : t('product.mobile'),
        image: {
          src: DeviceIcon,
          width: 12,
          height: plusIconSize
        }
      });
    }
  }

  if (
    showConsuptionPossibilities &&
    profile?.productMediaConsumer === 'VIDEO_ALL'
  ) {
    salesArguments.push({
      label: t('sales_arguments.content_offline'),
      image: {
        src: DownloadIcon,
        width: 12
      }
    });
  }

  if (totalDurationClasses && totalNumberClasses) {
    const labelTotalClasses = t('sales_arguments.classes', {
      count: totalNumberClasses
    });

    const labelTotalHours = t('sales_arguments.hours', {
      count: totalDurationClasses
    });

    salesArguments.push({
      label: `${labelTotalClasses} ${labelTotalHours}`,
      image: {
        src: HoursIcon,
        width: plusIconSize
      }
    });
  } else if (totalDurationClasses) {
    salesArguments.push({
      label: t('sales_arguments.content_hours', {
        count: totalDurationClasses
      }),
      image: {
        src: HoursIcon,
        width: plusIconSize
      }
    });
  } else if (totalNumberClasses) {
    salesArguments.push({
      label: t('sales_arguments.content_classes', {
        count: totalNumberClasses
      }),
      image: {
        src: HoursIcon,
        width: plusIconSize
      }
    });
  }

  if (profile?.totalUsers >= TOTAL_STUDENTS) {
    salesArguments.push({
      label: `+${t('product.total_students', {
        total: profile?.totalUsers
      })}`,
      image: {
        src: StudentsIcon,
        width: defaultIconSize
      }
    });
  }

  if (showSidebarScrolled && profile?.reviews?.totalPercent) {
    salesArguments.push({
      label: t('product.percentage_review', {
        percentage: profile?.reviews?.totalPercent
      }),
      image: {
        src: ReviewIcon,
        width: 20,
        height: 20
      }
    });
  }

  return salesArguments;
};
