import { RefObject } from 'react';
import Modal, {
  HTMLHotModalElement,
  ModalPosition
} from 'src/components/ModalCosmos';
import Faq from '../Faq/faq';
import { FaqProps } from '../Faq/types';

interface Props extends FaqProps {
  modalRef: RefObject<HTMLHotModalElement>;
}

const FaqModal = (props: Props) => {
  const { modalRef, ...rest } = props;
  const lockPageScroll = () => {
    if (window && document.body.style) {
      const scrollHeight = window.scrollY;

      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollHeight}px`;
    }
  };

  const unlockPageScroll = () => {
    if (window && document.body.style) {
      const scrollHeight = document.body.style.top;

      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollHeight || '0') * -1);
    }
  };

  return (
    <Modal
      ref={modalRef}
      className="faq-modal__container"
      onOpenModal={lockPageScroll}
      onCloseModal={unlockPageScroll}
      position={ModalPosition.TOP}
    >
      <Modal.Body className="faq-modal__body">
        <div className="_d-flex _justify-content-center _flex-column _w-full">
          <Faq {...rest} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FaqModal;
