import React from 'react';

import { isHighRated } from 'utils/data';
import { getLanguageSymbol } from 'utils/language';

import MainTitleDesktop from './Desktop';
import MainTitleMobile from './Mobile';

const MainTitle = ({ isMobile, profile }) => {
  const { product, customerReview, showReviewsOnProductProfile, productOwner = {} } = profile;
  const language = getLanguageSymbol(product?.language);
  const ratingStars =
    customerReview?.rating > 0 ? customerReview.rating.toFixed(1) : 0;
  const isHighRatedProduct = isHighRated(customerReview?.rating?.toFixed(1));
  const ownerHotmarter =
    productOwner && productOwner.name ? productOwner : product?.hotmarter;

  return isMobile ? (
    <MainTitleMobile
      product={product}
      ratingStars={ratingStars}
      customerReview={customerReview}
      language={language}
      showReviewsOnProductProfile={showReviewsOnProductProfile}
      ownerHotmarter={ownerHotmarter}
    />
  ) : (
    <MainTitleDesktop
      product={product}
      ratingStars={ratingStars}
      customerReview={customerReview}
      isHighRatedProduct={isHighRatedProduct}
      language={language}
      showReviewsOnProductProfile={showReviewsOnProductProfile}
    />
  );
};

export default React.memo(MainTitle);
