import React, { useState } from 'react';

import { useTranslation } from 'next-i18next';
import classnames from 'classnames';
import { loaderStaticImage } from 'utils/image';
import IconsImage from 'components/Images/Icons';

type ParagraphSectionProps = {
  text: string;
  className?: string;
  readMoreLimit?: number;
  readMoreClick?: () => void;
};

const MAX_LENGTH = 540;
const ChevronUpIcon = loaderStaticImage({
  src: 'static/images/icons/chevron-up.svg'
});
const ChevronDownIcon = loaderStaticImage({
  src: 'static/images/icons/chevron-down.svg'
});

const ParagraphSection = (props: ParagraphSectionProps) => {
  const { className } = props;
  const readMoreLimit = props.readMoreLimit || MAX_LENGTH;
  const textParagraphs = props.text?.split('\n');
  const textParagraphsParsed = textParagraphs?.filter(function (x) {
    return x;
  });
  const { t } = useTranslation();
  const [readMore, setReadMore] = useState(false);
  const showReadMore =
    props.text?.length > readMoreLimit || textParagraphsParsed?.length > 5;


  const sectionParagraphs = () => {
    return textParagraphsParsed.map((paragraph) => {
      return (
        <p key={paragraph} className="_mb-5" role="paragraph">
          {paragraph}
        </p>
      );
    });
  };

  const formatText = () => {
    const hasParagraphs = textParagraphsParsed?.length > 1;
    if (!hasParagraphs) {
      const renderedText =
        showReadMore && !readMore
          ? `${props.text?.slice(0, readMoreLimit)}...`
          : props.text;

      return <p role="paragraph">{renderedText}</p>;
    }

    return (
      <>
        {sectionParagraphs()}
      </>
    );
  };

  const changeReadMore = () => {
    setReadMore(!readMore);

    if (!readMore && props.readMoreClick) {
      props.readMoreClick();
    }
  };

  return (
    <>
      <div
        className={classnames('_w-full _line-height-lg _text-gray-600', {
          className,
          'product-description--hide': showReadMore && !readMore
        })}
      >
        {formatText()}
      </div>
      {showReadMore && (
        <div
          className="_mt-3 _font-weight-bold _text-decoration-underline _cursor-pointer"
          onClick={() => changeReadMore()}
        >
          <span className="_mr-2">
            {readMore
              ? `${t('product.show_less')}`
              : `${t('product.show_more')}`}
          </span>
          <IconsImage
            src={readMore ? ChevronUpIcon : ChevronDownIcon}
            width={12}
            height={6}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(ParagraphSection);
