import React from 'react';

import { loaderStaticImage } from '../../../utils/image';
import { counterBlackNovember } from 'utils/campaign';
import Utils from '../../../global/scripts/utils';

import IconsImage from 'components/Images/Icons';

const BlackNovemberBannerDesk = loaderStaticImage({
  src: 'static/images/black-november/logo-black-november.png'
});
const BlackNovemberBannerMobile = loaderStaticImage({
  src: 'static/images/black-november/logo-black-november-mobile.png'
});

const BlackNovemberBanner = ({ product, isMobile, t }) => {
  const totalDays = counterBlackNovember();

  if (Utils.isBlackNovember(product)) {
    return (
      <section className="section _mt-4 _mb-4 _mt-sm-4 _mb-sm-2">
        <div className="black-november-banner">
          <IconsImage
            src={isMobile ? BlackNovemberBannerMobile : BlackNovemberBannerDesk}
            width={120}
            height={62}
            className="black-november-banner__tag"
          />
          {isMobile && (
            <div className="black-november-banner__mobile">
              <div className="black-november-banner__text">
                {t('black_november.banner.banner_counter_days_5')}
                {` `}
                <div className="black-november-banner__text--highlight">
                  {t(
                    `black_november.banner.${
                      totalDays > 1
                        ? 'banner_counter_days_6'
                        : 'banner_counter_days_7'
                    }`,
                    { days: totalDays }
                  )}
                  {` `}
                </div>
              </div>
              <div className="black-november-banner__text">
                {t('black_november.banner.banner_counter_days_4')}
              </div>
            </div>
          )}
          {!isMobile && (
            <div className="black-november-banner__text">
              {t('black_november.banner.banner_counter_days_5')}
              {` `}
              <div className="black-november-banner__text--highlight">
                {t(
                  `black_november.banner.${
                    totalDays > 1
                      ? 'banner_counter_days_6'
                      : 'banner_counter_days_7'
                  }`,
                  { days: totalDays }
                )}
                {` `}
              </div>
              {t('black_november.banner.banner_counter_days_3')}
            </div>
          )}
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default BlackNovemberBanner;
