import React from 'react';
import { useTranslation } from 'next-i18next';
import ParagraphSection from 'components/@redesign/ParagraphSection';

type Props = {
  advantages: string;
};

const Advantages = ({ advantages }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="section" id="advantages" data-testid="product-advantages">
      <ParagraphSection text={advantages} />
    </div>
  );
};

export default React.memo(Advantages);
