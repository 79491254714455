import { useTranslation } from 'next-i18next';
import { loaderStaticImage } from 'utils/image';
import IconsImage from 'components/Images/Icons';
import RatingTotal from 'components/RatingTotal';
import ShareButton from 'components/@redesign/ShareButton';
import BlackNovemberBanner from 'components/Banner/BlackNovember';

const LanguageIcon = loaderStaticImage({
  src: 'static/images/icons/language.svg'
});
const HighRatedIcon = loaderStaticImage({
  src: 'static/images/icons/best_rated_black.svg'
});

const MainTitleDesktop = ({
  product,
  ratingStars,
  customerReview,
  isHighRatedProduct,
  language,
  showReviewsOnProductProfile
}) => {
  const { t } = useTranslation();
  const productTopic = product?.hotTopic?.topic
    ? `. ${t(`topic.${product?.hotTopic?.topic}`)}`
    : '';
  const languageName = t(`general.language.${language}`);

  return (
    <>
      <div className="_d-flex _flex-column _w-full _pt-2">
        <div>
          <h1 className="_mx-0 _mb-0 _mt-1 _text-md-5 _font-weight-bold _line-height redesigned-main-title">{product?.name}</h1>
        </div>
        <div className="_d-flex _mt-4 _justify-content-start _align-items-center _px-3 _py-2 _border _rounded-lg redesigned-main-title-tags">
          {showReviewsOnProductProfile && (
            <RatingTotal
              showReviewsOnProductProfile={showReviewsOnProductProfile}
              ratingStars={ratingStars}
              reviewTotalAnswers={customerReview?.totalReviews}
              showIcon={false}
              className="_d-flex _align-items-center redesigned-main-title-icon"
            />
          )}
          {isHighRatedProduct && (
            <span className="_d-flex _align-items-center redesigned-main-title-icon">
              <IconsImage src={HighRatedIcon} width={20} height={20} />
              <span className="_pl-2">
                {`${t('sales_arguments.best_reviewed')}`}
              </span>
            </span>
          )}
          <span className="_d-flex _align-items-center redesigned-main-title-icon">
            <IconsImage src={LanguageIcon} width={20} height={20} />
            <span className="_pl-2">{languageName}</span>
          </span>
          <div className="redesigned-main-title-icon redesigned-share-button">
            <ShareButton />
          </div>
        </div>
      </div>
      <BlackNovemberBanner
        product={product}
        isMobile={false}
        t={t}
      />
    </>
  );
};

export default MainTitleDesktop;
