import React from 'react';
import dayjs from 'dayjs';
import { loaderStaticImage } from 'utils/image';

import { useTranslation } from 'react-i18next';

import Utils from 'src/global/scripts/utils';
import IconsImage from 'components/Images/Icons';
import AvatarImage from 'components/Images/Avatar';

type SocialMediaProps = {
  icon: string;
  label: string;
};

type Props = {
  ownerHotmarter: HotmarterInfo;
  showSocialMedia?: boolean;
  socialMediaIcons?: Record<string, SocialMediaProps>;
  className?: string;
  title?: boolean;
  isMobile?: boolean;
};

const AuthorInfo = ({
  ownerHotmarter,
  showSocialMedia = false,
  socialMediaIcons,
  className = null,
  title = null,
  isMobile
}: Props) => {
  const { t } = useTranslation();
  const { avatarFinal, name, signUpDate, socialMedia, userSince } =
    ownerHotmarter;
  const blueIcons = {
    facebook: loaderStaticImage({
      src: 'static/images/icons/facebook-link-blue.svg'
    }),
    twitter: loaderStaticImage({
      src: 'static/images/icons/twitter-link-blue.svg'
    }),
    'youtube-play': loaderStaticImage({
      src: 'static/images/icons/youtube-link-blue.svg'
    }),
    instagram: loaderStaticImage({
      src: 'static/images/icons/instagram-link-blue.svg'
    }),
    sparkle: loaderStaticImage({
      src: 'static/images/icons/facebook-link-blue.svg'
    })
  };

  const dateString = () => {
    if (signUpDate) {
      const years = dayjs().diff(new Date(signUpDate), 'year');
      return years > 0
        ? t('hotmarter.years', {
            count: years
          })
        : '';
    } else if (userSince) {
      const currentDate = new Date();
      const years = currentDate.getFullYear() - userSince;
      return years > 0 ? t('hotmarter.years', { count: years }) : '';
    }
  };

  const showIconHover = (e, item) => {
    if (e?.target?.srcset && blueIcons[item]) {
      e.target.srcset = blueIcons[item];
    }
  };

  const hideIconHover = (e, icon) => {
    if (e?.target?.srcset && icon) {
      e.target.srcset = icon;
    }
  };

  const renderSocialMedia = () => {
    if (showSocialMedia) {
      return (
        <>
          {(socialMedia || []).map((item) => {
            const icon = socialMediaIcons[item.type]?.icon;
            return (
              Utils.isValidURL(item.link) && (
                <a
                  key={item.type}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={socialMediaIcons[item.type]?.label}
                  onMouseOver={(e) => showIconHover(e, item.type)}
                  onMouseLeave={(e) => hideIconHover(e, icon)}
                >
                  {icon && (
                    <IconsImage
                      src={icon}
                      className={item.type === 'facebook' ? 'icon-light' : ''}
                    />
                  )}
                </a>
              )
            );
          })}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <div className={`_d-flex _mt-3 ${className || ''}`}>
        <div className="_w-10 _h-10 _flex-shrink-0">
          <AvatarImage src={avatarFinal} width={64} height={64} />
        </div>
        <div className="_d-flex _justify-content-between _align-items-center _ml-3 _w-full">
          <div className="_flex-column _justify-center creator_info">
            {title && <div>{`${t('badge.title_author')}`}</div>}
            <div className="_font-weight-bold _line-height-lg	_text-gray-800 _text-2">
              {name}
            </div>
            <div className="_font-weight _line-height-lg	_text-gray-600 _text-1">
              {`${dateString()}`}
            </div>
          </div>
          {!isMobile && (
            <div className="redesigned-author__social _d-flex _mt-4">
              {renderSocialMedia()}
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className="redesigned-author__social _my-4">
          {renderSocialMedia()}
        </div>
      )}
    </>
  );
};

export default React.memo(AuthorInfo);
