import React, { Fragment, useContext } from 'react';

import dynamic from 'next/dynamic';
import classnames from 'classnames';
import { withTranslation } from 'next-i18next';

import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import config from '../../../../env.config';
import { categories, campaignSideBar } from '../../../constants';
import Utils from '../../../global/scripts/utils';
import LinkArrow from '../../LinkArrow';
import SessionPackageInfo from '../../SessionPackageInfo';
import { isSessionPackage } from 'utils/sessions';
import { renderPrice, renderInfoPrice } from '../Checkout/Price';
import {
  getAccessLinks,
  mountCheckoutLabel,
  mountCheckoutLabelKey
} from 'utils/checkout';
import { sendAnalyticsEvent } from 'utils/datalayer';
import { getEnvironment } from 'utils/data';
import ContentLoader from 'components/Loader/content';

const CalendarContainer = dynamic(
  import('components/Calendar/CalendarContainer')
);
const LinkButton = dynamic(import('components/LinkButton'));

type Props = {
  t: (label: string) => void;
  checkoutData: CheckoutAccess;
  campaignData: CampaignData;
  isMobile: boolean;
  language: string;
  productId: number;
};

export const BottomCheckout = (props) => {
  const { t, checkoutData, isMobile, language }: Props = props;

  const {
    checkoutLink,
    checkoutLabel,
    salesEnabled,
    title,
    isBuyable,
    accessCourse,
    discountValue,
    buyButtonOnClick,
    dataCheckout
  } = checkoutData;

  const context = useContext(ProfileContext);
  const { showIframe, query = {}, profile } = context;
  const hasPrice = title && title.priceTitle;
  const isCheckoutDisplayed = !accessCourse || !profile?.clubMembershipLink;
  const showPrice = hasPrice && isCheckoutDisplayed;
  const sessionPackage = isSessionPackage(context);
  const isBlackNovember = context.profile?.isBlackNovember;
  const campaignDataSections = {};

  const buyButtonOnClickHandler = () => {
    if (buyButtonOnClick && typeof buyButtonOnClick === 'function') {
      const hasTestAttribute =
        profile?.clubMembershipLink ||
        profile?.productMediaConsumer === 'VIDEO_ALL';
      if (hasTestAttribute) {
        window['optimizely'] = window['optimizely'] || [];
        window['optimizely'].push({
          type: 'event',
          eventName: 'add-to-cart',
          tags: {
            value: dataCheckout?.offer?.paymentInfoResponse?.offerPrice?.value
          }
        });
      }

      buyButtonOnClick();
    }
  };

  let { accessCourseLink, showAccessCourse, showCheckout } = getAccessLinks(
    accessCourse,
    config,
    isBuyable,
    profile?.clubMembershipLink
  );

  if (profile?.clubMembershipLink) {
    accessCourseLink = profile?.clubMembershipLink;
  }

  if (showAccessCourse && !accessCourseLink) {
    showAccessCourse = false;
  }

  const onAccessClick = () => {
    const cartStatus = salesEnabled ? 'OpenCart' : 'ClosedCart';
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: `${getEnvironment(query?.sck)}:Product`,
      event_action: `Click:AccessCourse:${cartStatus}`,
      event_label: `Product:${profile?.product?.productId}`
    });
  };

  const mountCheckoutLink = (props) => {
    const { priceTitle = '', infoPrice = '' } = title;
    const { profile = {} } = context;
    const checkoutLabelKey = mountCheckoutLabelKey(checkoutLabel, isMobile);

    const checkoutButtonLabel = mountCheckoutLabel(
      checkoutLabelKey,
      isBuyable,
      t,
      `${t('salesPage.access_product')}`
    );

    const checkoutAriaLabel = `${checkoutButtonLabel} ${priceTitle} ${
      infoPrice || ''
    }`;

    if (!isBuyable) {
      return (
        <LinkArrow
          href={profile?.clubMembershipLink}
          target="_blank"
          rel="noreferrer"
          title="Acessar"
          className={`btn-access-course-link btn-mobile-link`}
          onClick={onAccessClick}
        >
          {t('salesPage.own_this_product')}{' '}
          <strong>{`${t('salesPage.access_your_product')}`}</strong>
        </LinkArrow>
      );
    }

    switch (profile?.product?.format?.trim()) {
      case categories.SERVICE_ONLINE:
        return (
          <CalendarContainer
            checkoutLink={checkoutLink}
            modal={true}
            isMobile={isMobile}
            language={language}
            ucode={profile?.product?.ucode}
          />
        );
      case categories.EVENT_ONLINE:
        return (
          <LinkButton
            href={checkoutLink || accessCourseLink}
            size={!isMobile ? 'lg' : 'md'}
            text={
              checkoutLabel
                ? t(`salesPage.cta.${checkoutLabel}`)
                : t('salesPage.access_product')
            }
            icon={isBuyable ? 'ticket' : null}
            ctaIcon={
              campaignDataSections?.[campaignSideBar.MAIN]?.urlFinal || ''
            }
            onClick={() => {
              buyButtonOnClickHandler();
              if (!checkoutLabel) {
                onAccessClick();
              }
            }}
            prefix={'fal'}
            theme={`${isBuyable ? 'success' : 'blue'}`}
            ariaLabel={checkoutAriaLabel}
            className={classnames('btn-buy ', {
              'btn-buy-checkout': checkoutLabel,
              'btn-buy-access-course': !checkoutLabel
            })}
          >
            {checkoutLabel
              ? t(`salesPage.cta.${checkoutLabel}`)
              : t('salesPage.access_product')}
          </LinkButton>
        );
      default:
        return (
          showCheckout &&
          (checkoutLink || accessCourseLink) &&
          !showIframe && (
            <LinkButton
              href={checkoutLink || accessCourseLink}
              text={checkoutButtonLabel}
              onClick={buyButtonOnClickHandler}
              icon={isBuyable ? 'shopping-cart' : null}
              size={!isMobile ? 'lg' : 'md'}
              ariaLabel={checkoutAriaLabel}
              ctaIcon={campaignDataSections?.[campaignSideBar.MAIN]?.url}
              theme={`${isBuyable ? 'success' : 'blue'}`}
              className={`btn-buy ${
                checkoutLabel ? 'btn-buy-checkout' : 'btn-buy-access-course'
              }`}
            >
              {checkoutButtonLabel}
            </LinkButton>
          )
        );
    }
  };

  const renderTotalPrice = () => {
    const { profile = {} } = context;

    switch (profile?.product?.format) {
      case categories.EVENT_ONLINE:
        return (
          <div className="title-event">
            <div className="title-area-ticket">
              <span>{`${t('online_event.ticket')}`}</span>
            </div>

            {showPrice && (
              <div className="price-area">
                {isCheckoutDisplayed &&
                  renderPrice(
                    title,
                    discountValue,
                    context,
                    campaignDataSections
                  )}
              </div>
            )}

            {renderInfoPrice(
              title,
              context,
              false,
              Utils.isEventOnline(profile?.product?.format)
            )}
          </div>
        );
      default:
        return (
          <Fragment>
            <div className="title-area">
              {isBuyable && (
                <div className="title">
                  {context?.checkout?.loading && (
                    <ContentLoader width="70%" height={30} />
                  )}
                  {!context?.checkout?.loading && (
                    <span className="title-area--open">
                      {profile?.product?.name}
                    </span>
                  )}
                </div>
              )}
              {!isBuyable && (
                <span className="title-area--closed">
                  {`${t('salesPage.cta.checkoutClosed')}`}
                </span>
              )}
            </div>
            {showPrice && (
              <div className="price-area">
                {isCheckoutDisplayed &&
                  renderPrice(
                    title,
                    discountValue,
                    context,
                    campaignDataSections
                  )}
              </div>
            )}
          </Fragment>
        );
    }
  };

  return (
    <div className="checkout-common">
      <div
        className={classnames('checkOut', 'bottom-checkout', {
          'has-price': hasPrice && isCheckoutDisplayed,
          'show-checkout': isCheckoutDisplayed,
          'bottom-checkout--session-package': sessionPackage,
          'bottom-checkout--bn': isBlackNovember
        })}
        data-testid="checkout-bottom"
      >
        {sessionPackage && (
          <SessionPackageInfo
            mobile
            t={t}
            info={context.online_service.sessionInfo}
          />
        )}

        <div
          className={classnames('bottom-checkout-content', {
            '_mt-2': context.checkout?.data?.checkoutClosed
          })}
        >
          <div
            className={classnames('bottom-checkout-content-title', {
              'bottom-checkout-content--closed':
                context.checkout?.data?.checkoutClosed
            })}
          >
            {renderTotalPrice()}
          </div>

          <div
            className={classnames(
              'button-area',
              'bottom-checkout-content-button',
              { 'checkout-closed': context.checkout?.data?.checkoutClosed }
            )}
          >
            {mountCheckoutLink({ ...props })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(React.memo(BottomCheckout));
