import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import { useTranslation } from 'next-i18next';
import { extractPromptAI } from 'utils/string';
import { promptAI } from '../../../constants';
import Tabs from 'components/Tabs';
import { isEventOnline } from 'utils/product/format';

const Modules = dynamic(() => import('components/ProductDetails/Modules'));
const Advantages = dynamic(() => import('./Advantages'));
const DataSheet = dynamic(() => import('./DataSheet'));
const Schedule = dynamic(() => import('components/Schedule'));

const ProductDetails = () => {
  const context = useContext(ProfileContext);
  const { t } = useTranslation();
  const { productSummary, product, showModuleContentSummarized } =
    context.profile;
  let advantages = product?.advantages;
  let totalModulesContent =
    productSummary && productSummary.modules.length >= 1;

  if (showModuleContentSummarized) {
    totalModulesContent =
      context.profile?.clubContentSummarized &&
      context.profile?.clubContentSummarized?.modules.length >= 1;
  }

  if (!product?.advantages) {
    advantages = extractPromptAI(
      context.profile?.hotmartAIs,
      promptAI?.ADVANTAGES
    );
  }

  return (
    <Tabs>
      {totalModulesContent && (
        <div
          data-label={t('badge.digital_title')}
          data-tab="tab-modules"
          data-name="Content"
        >
          <Modules profile={context.profile} className="redesigned-modules" />
        </div>
      )}
      {advantages && (
        <div
          data-label={t('product.advantages')}
          data-tab="tab-advantages"
          data-name="Advantages"
        >
          <Advantages advantages={advantages} />
        </div>
      )}
      {isEventOnline(product?.format) &&
        product?.dataSheet?.eventSchedule && (
          <div
            data-label={t('product.schedule')}
            data-tab="tab-events"
            data-name="OnlineEvent"
          >
            <Schedule schedule={product?.dataSheet?.eventSchedule} />
          </div>
        )}
      {product?.dataSheet && (
        <div
          data-label={t('product.details')}
          data-tab="tab-details"
          data-name="Details"
        >
          <DataSheet profile={context.profile} t={t} />
        </div>
      )}
    </Tabs>
  );
};

export default React.memo(ProductDetails);
