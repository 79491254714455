import { useContext, Ref } from 'react';
import dynamic from 'next/dynamic';

import { useTranslation } from 'react-i18next';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
const Cover = dynamic(() => import('components/Cover'));

type VideoProps = {
  isMobile: boolean;
  coverRef: Ref<HTMLDivElement>;
  isRedesigned?: boolean;
  hasVideoDescription?: boolean;
};

const Video = (props: VideoProps) => {
  const { t } = useTranslation();
  const { profile } = useContext(ProfileContext);

  if (!props?.hasVideoDescription) {
    return null;
  }

  return (
    <div className="section section-divider">
      <Cover
        coverRef={props?.coverRef}
        videoLink={profile?.product?.videoLink}
        productImage={profile.product?.avatarFinal}
        isMobile={props.isMobile}
        dataSheet={profile?.product?.dataSheet}
        poster={!props.isMobile ? 'hqdefault' : 'mqdefault'}
        title={profile.product?.name}
        description={profile.product?.copy}
        date={profile.createdAt}
        isRedesigned={props.isRedesigned}
      />
    </div>
  );
};

export default Video;
