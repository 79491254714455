import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

import DatahubService from 'utils/datahub';
import Profile from '../../../@profile/service';
import Card from '../../Card';
import { EVENT_ACTIONS, CLICK_SOURCES } from 'utils/datahub.constants';
import { getSessionId } from 'utils/cookies';
import { useTranslation } from 'react-i18next';
import { cardTypes } from '../../../constants';
import IconsImage from '../../Images/Icons';
import { loaderStaticImage } from 'utils/image';

const ChevronWhite = loaderStaticImage({src: 'static/images/icons/arrow-solid-white.svg' });
const Glider = dynamic(() => import('react-glider'));

type RelatedProductsProps = {
  ip: string;
  isMobile: boolean;
  producerReferenceCode: string;
  producerUserId: number;
  showRelatedProducts: boolean;
  setRelatedProductsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const RelatedProducts = ({ ...props }: RelatedProductsProps) => {
  const { t } = useTranslation();
  const { setRelatedProductsActive } = props;
  const [otherProducts, setOtherProducts] = useState([]);
  const sectionTitle = t('general.related_products');

  const getOtherProducts = async (
    producerReferenceCode: string,
    producerUserId: number
  ) => {
    try {
      const otherProducts = await Profile.getOtherProducts(
        producerReferenceCode,
        producerUserId
      );
      if (
        otherProducts &&
        Array.isArray(otherProducts) &&
        otherProducts.length > 0
      ) {
        setOtherProducts(
          otherProducts?.filter((product) => {
            return !!product.slug;
          })
        );
      } else {
        setOtherProducts(null);
        setRelatedProductsActive(false)
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.producerReferenceCode && props.showRelatedProducts) {
      new Promise((resolve, reject) => {
        resolve(
          getOtherProducts(props.producerReferenceCode, props.producerUserId)
        );
      });
    }
  }, [props.showRelatedProducts]);

  const slidesToShow = props.isMobile ? 1.5 : 3.5;
  const totalProducts = otherProducts && Object.keys(otherProducts)?.length;
  const hasButtons = (props.isMobile && totalProducts > 1) || (!props.isMobile && totalProducts > 3)

  const onProductClick = (product) => {
    DatahubService.sendEvent({
      action: EVENT_ACTIONS.CLICK,
      sessionId: getSessionId(props.ip),
      productId: product?.productId,
      source: CLICK_SOURCES.PRODUCT_PAGE
    });
  };

  if (!otherProducts || !totalProducts) {
    return null;
  }

  if (otherProducts && totalProducts > 0) {
    return (
      <div className="related-products">
        <h2 className="_w-full _mb-5 _text-md-5 _font-weight-bold">{sectionTitle}</h2>
        <Glider
          draggable
          hasArrows={hasButtons}
          hasDots={false}
          slidesToScroll={1}
          slidesToShow={slidesToShow}
          className="gradient-outline"
          iconRight={<IconsImage src={ChevronWhite} width={20} height={20} />}
          iconLeft={<IconsImage src={ChevronWhite} width={20} height={20} />}
        >
          {otherProducts &&
            otherProducts.map((product, index) => {
              return (
                <div key={index}>
                  <Card
                    product={product}
                    type={cardTypes.PRODUCT_CARD}
                    sectionTitle={sectionTitle}
                    isMobile={false}
                    className="product-card--redesign"
                    onClick={() => onProductClick(product)}
                  />
                </div>
              );
            })}
        </Glider>
      </div>
    );
  }
};

export default React.memo(RelatedProducts);
