import React, { useContext } from 'react';

import { mountSalesArguments } from 'utils/datasheet';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import { v4 as uuid } from 'uuid';

const DataSheet = ({ profile, t }) => {
  const { product = {} } = profile;
  const context = useContext(ProfileContext);
  const showConsumptionPossibilities =
    context.abTestFlags?.showConsumptionPossibilities;

  const targetAudience = product?.dataSheet?.target;
  const salesArguments = mountSalesArguments(
    t,
    profile,
    product?.dataSheet,
    true,
    showConsumptionPossibilities
  );

  return (
    <>
      <div className="product-data">
        {salesArguments?.map((salesArgument, index) => {
          const IconComponent = salesArgument?.image?.src || null

          return (<div key={uuid()} className="_d-flex _align-items-center _mb-4 _mr-5">
            {IconComponent ? (
              <IconComponent
                width={salesArgument?.image?.width}
                height={salesArgument?.image?.height || 16}
              />
            ) : null}
            <div
              key={`argument-${index}`}
              className="_font-weight _ml-4"
              aria-label={salesArgument?.label}
            >
              {salesArgument?.label}
            </div>
          </div>
          )
        })}
      </div>
      {targetAudience && (
        <div className="_pt-4">
          <span className="_text-3 _font-weight-bold">
            {t('data_sheet.target_new')}
          </span>
          <p>{targetAudience}</p>
        </div>
      )}
    </>
  );
};

export default DataSheet;
