import React, { useContext, Fragment } from 'react';

import { useTranslation } from 'next-i18next';

import {
  createCheckoutLabel,
  isBuyableCheckout,
  mountPriceTitle,
  getDiscount,
  mountPriceOffer,
  mountAccessCourseLabel
} from 'utils/checkout';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import { createCheckoutURL } from 'utils/checkout';
import { buyButtonOnClick } from 'utils/events';

import SideCheckout from './SideCheckout';
import BottomCheckout from './BottomCheckout';

type Props = {
  showAtBottom: boolean;
  accessCourse: any;
  showSidebarScrolled: boolean;
  sidebarScrolled: boolean;
  isMobile: boolean;
  isBlackNovember: boolean;
  ip: string;
  campaign: any;
};

const Checkout = (props) => {
  const {
    showAtBottom,
    accessCourse,
    showSidebarScrolled,
    sidebarScrolled,
    isMobile,
    isBlackNovember,
    campaign = {},
    ip
  }: Props = props;
  const { t } = useTranslation();
  const context = useContext(ProfileContext);
  const { salesEnabled = false, product = {} } = context.profile;
  const { off, src, sck, coupon, hotfeature } = context.checkout;
  const dataCheckout = context?.checkout?.data;
  let title = {
    infoPrice: '',
    paymentInInstallments: '',
    pricePreviousValue: '',
    priceRecurrence: '',
    priceTitle: '',
    promptPayment: '',
    coupon: 0,
    vat: ''
  };
  const {
    offer,
    locale,
    maxChargeCycles,
    productType,
    newCheckout = false
  } = dataCheckout;
  const { producerReferenceCode, cta, language } = product;

  const accessCourseLabel = mountAccessCourseLabel(accessCourse, t);

  const { productCoupon, discountValue } = getDiscount(
    context,
    coupon,
    product,
    isBlackNovember,
    newCheckout,
    offer
  );

  let checkoutLink = createCheckoutURL(
    cta,
    producerReferenceCode,
    off,
    src,
    sck,
    productCoupon,
    hotfeature
  );
  let checkoutLabel = createCheckoutLabel({ offer, cta, context });
  let isBuyable = isBuyableCheckout(checkoutLabel);

  if (newCheckout) {
    title = mountPriceOffer(offer?.paymentMethods, locale, productType, t);
  } else {
    if (offer) {
      title = mountPriceTitle(
        offer.paymentInfoResponse,
        maxChargeCycles,
        productType,
        t
      );
    }
  }

  const checkoutData = {
    checkoutLink,
    checkoutLabel,
    dataCheckout,
    title,
    isBuyable,
    accessCourse,
    accessCourseLabel,
    discountValue: discountValue || title?.coupon,
    buyButtonOnClick: () => buyButtonOnClick(context, ip),
    salesEnabled
  };

  if (!showAtBottom) {
    return (
      <SideCheckout
        {...{
          isMobile,
          checkoutData,
          campaignData: campaign,
          showSidebarScrolled,
          sidebarScrolled
        }}
      />
    );
  }
  if (!context.sidebar.opened && context?.profile?.salesEnabled) {
    return (
      <Fragment>
        <BottomCheckout
          {...{
            checkoutData,
            isMobile,
            campaignData: campaign,
            language
          }}
        />
      </Fragment>
    );
  }
  return null;
};

export default Checkout;
