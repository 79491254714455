import React from 'react';
import { accordionTypes } from '../../../constants';
import { useTranslation } from 'next-i18next';
import { faqItems } from './index';
import { FaqProps } from './types';
import { FAQPage } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';
import Collapsible from './collapsible';

const Faq = (props: FaqProps) => {
  const { t } = useTranslation();
  const { items, mainEntity } = faqItems(props);

  return (
    <div className="faq-container">
      {mainEntity?.length > 0 && (
        <JsonLd<FAQPage>
          item={{
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: mainEntity
          }}
        />
      )}

      <h2 className="_w-full _mb-4 _text-md-5 _font-weight-bold">{`${t(
        'faq.title_new'
      )}`}</h2>

      <Collapsible
        items={items}
        type={accordionTypes.FAQ}
        className="redesigned-modules"
        productId={props?.profile?.product?.productId}
        environment={props?.environment}
      />
    </div>
  );
};

export default React.memo(Faq);
